import { useState, useRef, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { PageTitleArea, ManageLayout, MainContainer } from "../layout/Layout";
import { Table } from "../ag-grid/Ag-grid";
import { useStore } from "../../store/Store";
import { PATH } from "../../utils/urls";
import { VerticalMenu } from "../reusable/VerticalMenu/VerticalMenu";
import { StatusBadge } from "../reusable/StatusBadge";
import { DownloadTableButton } from "../reusable/DownloadTableButton";

const RecTypesConfigDash = observer(({}) => {
  const { recTypeStore, teamStore } = useStore();
  const gridRef = useRef(null);
  const navigate = useNavigate();
  const [initialised, setInitialised] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId") ? Number(searchParams.get("teamId")) : teamStore.getSelectedTeam().id;
  const recTypes = recTypeStore.getRecTypes();

  const populateTable = () => {
    let rows = [];
    recTypes.forEach((recType) => {
      rows.push({ id: recType.id, name: recType.name, description: recType.description, status: recType.fourEyes.status });
    });
    return rows;
  };

  useEffect(() => {
    if (!initialised && teamId && recTypes !== null) {
      recTypeStore.setSelectedRecType(null);
      setInitialised(true);
    }
  });

  const getMenuItems = ({ teamId, data }) => {
    return [
      { key: "configuration", label: "Configuration", visible: true, link: `${PATH.REC_TYPE_CONFIG}?teamId=${teamId}&recType=${data.id}&tab=0` },
      { key: "dataVerification", label: "Data Verification", link: `${PATH.REC_TYPE_CONFIG}?teamId=${teamId}&recType=${data.id}&tab=1` },
      { key: "matchingRules", label: "Matching Rules", link: `${PATH.REC_TYPE_CONFIG}?teamId=${teamId}&recType=${data.id}&tab=2` },
    ];
  };

  const cols = [
    {
      headerName: "Name",
      field: "name",
    },
    { headerName: "Description", field: "description" },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: (params) => {
        return <StatusBadge status={params.data.status} />;
      },
    },
    {
      field: "expandRow",
      width: 50,
      maxWidth: 50,
      cellRenderer: (params) => {
        return <VerticalMenu teamId={teamStore.getSelectedTeam().id} data={params.data} getMenuItemsFromRowData={getMenuItems} />;
      },
    },
  ];

  const createFileName = () => {
    const fileName = `All reconciliation types for ${teamStore.getSelectedTeam().name}`;
    const fullStopsRemoved = fileName.replaceAll(".", "");
    return fullStopsRemoved;
  };

  return (
    // PageTitle Area props = <CreateRecTypePanel />
    <MainContainer>
      <PageTitleArea
        backButtonEnabled={false}
        title="Reconciliations"
        description={<div className="text-medium pb-4">View your teams reconciliation types</div>}
        borderBottom={true}
        props={<DownloadTableButton gridRef={gridRef} createFileName={createFileName} />}
      />
      <ManageLayout>
        <div className="mt-32"></div>
        <Table rowSelection="single" columnDefs={cols} rowData={recTypes === null ? null : populateTable()} ref={gridRef} colFlex={true} />
      </ManageLayout>
    </MainContainer>
  );
});

export { RecTypesConfigDash };
