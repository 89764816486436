import { observer } from "mobx-react-lite";
import { useSearchParams, Link } from "react-router-dom";
import { Text, Button, DropdownListItem, DropdownItem, ItemBody, IconVerticalMenu, modalInstance } from "@fundrecs/ui-library";
import { StatusBadge } from "../reusable/StatusBadge";
import { PATH } from "../../utils/urls";
import { MODALS, STATUS } from "../../utils/enums";
import { getDateStringFromTimestamp, getDayString } from "../../utils/dates";

const RecOptions = observer(({ recType, selectedRec, selectedAccount, selectedDate }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");

  const linkToRec = `${PATH.REC}?teamId=${teamId}&recTypeId=${recType ? recType.id : ""}&recId=${selectedRec ? selectedRec.id : ""}&accId=${
    selectedAccount ? selectedAccount.id : ""
  }`;

  return selectedRec && selectedAccount && selectedDate ? (
    <>
      <div className="d-flex justify-content-between">
        <Text size="md" weight="bold">
          {getDayString(selectedDate)}
        </Text>
        <ActionsDropdown teamId={teamId} selectedRec={selectedRec} linkToRec={linkToRec} selectedAccount={selectedAccount} recType={recType} />
      </div>
      {selectedRec ? (
        <>
          <div
            className="mt-32 pl-8 pr-8 pt-8 pb-8"
            style={{
              borderRadius: "6px 6px 0px 0px",
              background: selectedRec && selectedRec.fourEyes ? STATUS[selectedRec.fourEyes.status]["lightColor"] : "",
            }}
          >
            <div className="d-flex justify-content-between">
              <Text size="sm" weight="bold">
                {selectedAccount ? selectedAccount.name : ""}
              </Text>
              <StatusBadge status={selectedRec && selectedRec.fourEyes ? selectedRec.fourEyes.status : ""} recStatus={true} />
            </div>
          </div>
          <div
            className="mb-32 pl-8 pr-8 pt-8 pb-8"
            style={{
              borderRadius: "0px 0px 6px 6px",
              background: selectedRec && selectedRec.fourEyes ? STATUS[selectedRec.fourEyes.status]["backgroundColor"] : "",
            }}
          >
            <div className="d-flex justify-content-between">
              <div></div>
              <Link className="text-decoration-none" to={linkToRec}>
                <Button size="sm">
                  <Text size="xs">Go to reconciliation</Text>
                </Button>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <div className="mt-24 d-flex justify-content-center">
          <Text variant="tertiary" size="sm" weight="regular">
            No reconciliations
          </Text>
        </div>
      )}
    </>
  ) : (
    ""
  );
});

const ActionsDropdown = ({ teamId, selectedRec, linkToRec, selectedAccount, recType }) => {
  const canUpload = selectedRec && selectedRec.fourEyes ? [STATUS.DRAFT.status, STATUS.RESTRICTED.status].includes(selectedRec.fourEyes.status) : false;

  return selectedRec ? (
    <div style={{ width: "100px", alignItems: "right" }}>
      <button data-bs-toggle="dropdown" type="button" className="btn dropdown-button dropdown-button-sm">
        <Text size="xs">Actions</Text>
        <span className="ml-8"></span>
        <IconVerticalMenu className="btn-sm-svg" />
      </button>
      <ul
        class="dropdown-menu dropdown-menu-end box dropdown-item-text-undefined"
        aria-labelledby="dropdownMenuLink"
        style={{ left: "200px", width: "300px", maxHeight: "300px", background: "#fff", overflowX: "scroll" }}
      >
        <Link className="text-decoration-none" to={linkToRec}>
          <DropdownListItem key={Math.random()} onClick={() => {}}>
            <DropdownItem active={false} index={0}>
              <ItemBody>Go to reconciliation</ItemBody>
            </DropdownItem>
          </DropdownListItem>
        </Link>
        {false ? (
          <>
            <DropdownListItem key={Math.random()} onClick={() => {}} style={{ borderTop: "1px solid #CDD0D7" }}>
              <DropdownItem active={false} index={0}>
                <ItemBody>Skip today</ItemBody>
              </DropdownItem>
            </DropdownListItem>
            <DropdownListItem key={Math.random()} onClick={() => {}}>
              <DropdownItem active={false} index={0}>
                <ItemBody>Skip to a date</ItemBody>
              </DropdownItem>
            </DropdownListItem>
            <DropdownListItem key={Math.random()} onClick={() => {}} style={{ borderBottom: "1px solid #CDD0D7" }}>
              <DropdownItem active={false} index={0}>
                <ItemBody>Set as day one</ItemBody>
              </DropdownItem>
            </DropdownListItem>
          </>
        ) : (
          ""
        )}

        <DropdownListItem
          key={Math.random()}
          onClick={() => {
            if (canUpload) {
              modalInstance(MODALS.MANUAL_UPLOAD).show();
            }
          }}
        >
          {canUpload ? (
            <DropdownItem active={false} index={0}>
              Upload file
            </DropdownItem>
          ) : (
            <div className="dropdown-item">
              <Text variant="tertiary" size="sm">
                Upload file
              </Text>
            </div>
          )}
        </DropdownListItem>
        {selectedRec && selectedRec.startDate ? (
          <Link
            className="text-decoration-none"
            to={`${PATH.REC_AUDIT_TRAIL}?teamId=${teamId}&recType=${recType.name}&account=${selectedAccount.name}&recId=${
              selectedRec.id
            }&date=${getDateStringFromTimestamp(selectedRec.startDate)}`}
          >
            <DropdownListItem key={Math.random()} onClick={() => {}}>
              <DropdownItem active={false} index={0}>
                <ItemBody>Audit trail</ItemBody>
              </DropdownItem>
            </DropdownListItem>
          </Link>
        ) : (
          ""
        )}
      </ul>
    </div>
  ) : (
    ""
  );
};

export { RecOptions };
