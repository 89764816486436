import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { AUTHORITIES, AUTH_ROUTES, STATUS } from "../utils/enums";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";

class RecStore {
  selectedRec = {};

  constructor() {
    makeAutoObservable(this);
  }

  getSelectedRec = () => {
    return toJS(this.selectedRec);
  };

  getRecById = async (teamId, recId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("getRecById: unauthorised");
      return { status: 401 };
    }
    let rec = null;
    const resp = await fusionRecsApi.rec.getRecById(teamId, recId);
    if (resp.status === 200) {
      rec = resp.data;
      this.setSelectedRec(rec);
    } else {
      uiStore.errorNotification(resp.data);
    }
    return rec;
  };

  getRecsInDateRange = async (teamId, data) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("getRecsInDateRange: unauthorised");
      return [];
    }
    let rows = [];
    const resp = await fusionRecsApi.rec.getRecsInDateRange(teamId, data);
    if (resp.status === 200) {
      rows = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return rows;
  };

  setSelectedRec = (rec) => {
    this.selectedRec = rec;
  };

  canSubmit = async (teamId, recId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("canSubmit: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.rec.canSubmit(teamId, recId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  getUnmatchedRows = async (teamId, recId, subAccountUuid, recVersion, pageNo = 0) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("getUnmatchedRows: unauthorised");
      return [];
    }
    let rows = [];
    const resp = await fusionRecsApi.rec.getUnmatchedRows(teamId, recId, subAccountUuid, recVersion, pageNo);
    if (resp.status === 200) {
      rows = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return rows;
  };

  pollRecStatus = async (teamId, recId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("pollRecStatus: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.rec.pollRecStatus(teamId, recId);
    return resp;
  };

  runAllAutomatchRules = async (teamId, recId, version, subAccountId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULES_RUN]) })) {
      console.log("runAllAutomatchRules: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.rec.runAllAutomatchRules(teamId, recId, version, subAccountId);
    if (resp.status === 200) {
      uiStore.addNotification("success", "Automatch rules running");
      this.setSelectedRec({ ...this.selectedRec, fourEyes: { ...this.selectedRec.fourEyes, status: STATUS.IN_PROGRESS.status } });
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  runMatchingRule = async (teamId, recId, version, matchingRuleId, data) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULES_RUN]) })) {
      console.log("runMatchingRule: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.rec.runMatchingRule(teamId, recId, version, matchingRuleId, data);
    if (resp.status === 200) {
      if (data.toBePersisted) {
        uiStore.addNotification("success", "Matching rule successfully run");
        this.updateRecVersion(resp.data.recVersion);
      }
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  manuallyCreateRowGroup = async (teamId, recId, version, data) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_MANUALLY_CREATE]) })) {
      console.log("manuallyCreateRowGroup: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.rec.manuallyCreateRowGroup(teamId, recId, version, data);
    if (resp.status === 200) {
      if (data.toBePersisted) {
        uiStore.addNotification("success", "Rows successfully submitted");
        this.updateRecVersion(resp.data.recVersion);
      }
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  removeMatch = async (teamId, recId, subAccountUuid, version, data) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_REMOVE]) })) {
      console.log("removeMatch: unauthorised");
      return { status: 401 };
    }
    let result = {};
    const resp = await fusionRecsApi.rec.removeMatch(teamId, recId, subAccountUuid, version, data);
    if (resp.status === 200) {
      uiStore.addNotification("success", "Match(es)/ Break(s) successfully removed");
      result = resp.data;
      this.updateRecVersion(resp.data);
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };

  submitRec = async (teamId, recId, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_SUBMIT]) })) {
      console.log("submitRec: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.rec.submitRec(teamId, recId, version);
    if (resp.status === 200) {
      uiStore.addNotification("success", "Rec successfully submitted");
      this.setSelectedRec(resp.data);
      result = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };

  rejectRec = async (teamId, recId, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_REJECT]) })) {
      console.log("rejectRec: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.rec.rejectRec(teamId, recId, version);
    if (resp.status === 200) {
      uiStore.addNotification("success", "Rec successfully rejected");
      this.setSelectedRec(resp.data);
      result = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };

  approveRec = async (teamId, recId, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_APPROVE]) })) {
      console.log("approveRec: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.rec.approveRec(teamId, recId, version);
    if (resp.status === 200) {
      this.setSelectedRec(resp.data);
      result = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };

  reopenRec = async (teamId, recId, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_REOPEN]) })) {
      console.log("reopenRec: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.rec.reopenRec(teamId, recId, version);
    if (resp.status === 200) {
      this.setSelectedRec(resp.data);
      result = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };

  cancelReport = async (teamId, recId, version) => {
    if (
      !isUserAuthorized({
        teamId: teamId,
        oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_SUBMIT, AUTHORITIES.RECS_REC_APPROVE, AUTHORITIES.RECS_REC_REJECT]),
      })
    ) {
      console.log("cancelReport: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.rec.cancelReport(teamId, recId, version);
    if (resp.status === 200) {
      this.setSelectedRec(resp.data);
      result = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };

  getUploadedFilesForRec = async (teamId, recId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("getUploadedFilesForRec: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.rec.getUploadedFilesForRec(teamId, recId);
    if (resp.status === 200) {
      result = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };

  getRowGroupsForSubAccount = async (teamId, recId, subAccountUuid, recVersion, pageNo = 0) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("getRowGroupsForSubAccount: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.rec.getRowGroupsForSubAccount(teamId, recId, subAccountUuid, recVersion, pageNo);
    if (resp.status === 200) {
      result = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };

  getRowGroupsForAllSubAccounts = async (teamId, recId, recVersion, pageNo = 0) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("getRowGroupsForAllSubAccounts: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.rec.getRowGroupsForAllSubAccounts(teamId, recId, recVersion, pageNo);
    if (resp.status === 200) {
      result = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };

  saveNotesToRowGroup = async (teamId, groupId, version, data) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_ADD_NOTE]) })) {
      console.log("saveNotesToRowGroup: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.rec.saveNotesToRowGroup(teamId, groupId, version, data);
    if (resp.status === 200) {
      uiStore.addNotification("success", `Note successfully attached`);
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  removeTagFromRowGroup = async (teamId, groupId, noteId, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_REMOVE_NOTE]) })) {
      console.log("removeTagFromRowGroup: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.rec.removeTagFromRowGroup(teamId, groupId, noteId, version);
    if (resp.status === 200) {
      uiStore.addNotification("success", `Note successfully removed`);
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  saveCommentToRowGroup = async (teamId, groupId, version, data) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ROW_GROUP_ADD_COMMENT]) })) {
      console.log("saveCommentToRowGroup: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.rec.saveCommentToRowGroup(teamId, groupId, version, data);
    if (resp.status === 200) {
      uiStore.addNotification("success", `Comment successfully updated`);
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  getRowsForRowGroup = async (teamId, rowGroupId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("getRowsForRowGroup: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.rec.getRowsForRowGroup(teamId, rowGroupId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      result = resp.data;
    }
    return result;
  };

  fetchRowCounts = async (teamId, recId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("fetchRowCounts: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.rec.fetchRowCounts(teamId, recId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      result = resp.data;
    }
    return result;
  };

  fetchRowCountForTable = async (teamId, recId, subAccountId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("fetchRowCountForTable: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.rec.fetchRowCountForTable(teamId, recId, subAccountId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      result = resp.data;
    }
    return result;
  };

  fetchRecAudit = async (teamId, recId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("fetchRecAudit: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.rec.fetchRecAudit(teamId, recId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  rowCountPerCategory = async (teamId, recId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("rowCountPerCategory: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.rec.rowCountPerCategory(teamId, recId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  updateRecVersion = (version) => {
    this.setSelectedRec({ ...this.selectedRec, version: version });
  };
}

export { RecStore };
